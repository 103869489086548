import React from 'react'
import { useTranslation } from 'react-i18next'

export default function App() {
  const { t } = useTranslation()

  return (
    <section className="app">
      <h1>{t('titles.hello')}</h1>
    </section>
  )
}
